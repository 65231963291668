export default function SectionHeading({ text, white = false }) {
  return (
    <>
      <h2
        className={`mx-auto uppercase text-2xl text-center ${
          white ? "text-white" : "text-gray-800"
        }`}
      >
        {text}
      </h2>

      <hr
        className={`w-[50px] h-1 mx-auto mt-2 border-0 rounded ${
          white ? "bg-white" : "bg-gray-800"
        }`}
      />
    </>
  )
}
