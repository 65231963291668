import { Link } from "react-router-dom"
import Container from "./Container"
import SectionHeading from "./SectionHeading"

export default function CallForAction() {
  return (
    <section className="w-full py-12 bg-white">
      <Container>
        <div className="bg-gradient-to-tr from-ng-lightblue via-ng-lightblue to-ng-green p-4 rounded-md text-white text-center relative">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-24 h-24 absolute bottom-0 left-0 z-[1000]"
          >
            <path
              fillRule="evenodd"
              d="M8.25 3.75H19.5a.75.75 0 01.75.75v11.25a.75.75 0 01-1.5 0V6.31L5.03 20.03a.75.75 0 01-1.06-1.06L17.69 5.25H8.25a.75.75 0 010-1.5z"
              clipRule="evenodd"
            />
          </svg> */}

          <div className="my-2 ">
            <SectionHeading text="Call for Action" white={true} />
          </div>
          <div className="my-4">
            <p className=" md:text-xl text-lg">
              We seek committed researchers like you to join our open science
              <br />
              <br />
              If you are interested, please:
            </p>
            <Link to="/contact">
              <button className="rounded-md bg-white text-ng-lightblue font-bold px-4 py-2 text-lg md:text-xl mt-6">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </Container>
    </section>
  )
}
