import frank_piller from "../images/team/frank_piller.png"
import theresa_lang from "../images/team/theresa_lang.jpeg"
import gabriele_gramelsberger from "../images/team/gabriele_gramelsberger.png"
import ContactMember from "./ContactMember"
import Container from "./Container"
import SectionHeading from "./SectionHeading"

export default function Contacts() {
  return (
    <Container>
      <div className="my-2">
        <SectionHeading text="Contact" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
        {contacts.map((contact, index) => (
          <ContactMember member={contact} key={contact.name + index} />
        ))}
      </div>
    </Container>
  )
}

const contacts = [
  {
    name: "Frank Piller",
    picture: frank_piller,
    institute:
      "Institute for Technology & Innovation Management <br> RWTH Aachen University",
    url: "https://www.time.rwth-aachen.de/cms/TIME/Die-Research-Area/~ehfb/Technologie-und-Innovationsmanagement-/?lidx=1",
    address: "Kackertstraße 7, 3. Etage, 52072 Aachen",
    email: "piller@time.rwth-aachen.de",
  },
  {
    name: "Gabriele Gramelsberger",
    picture: gabriele_gramelsberger,
    institute: `Chair of the Philosophy of Science and Technology <br> RWTH Aachen University<br>Käte Hamburger International Center „Research Cultures“`,
    url: "https://www.css-lab.rwth-aachen.de/team/gramelsberger",
    address: "Theaterplatz 14, 52062 Aachen",
    email: "gramelsberger@humtec.rwth-aachen.de",
  },
  {
    name: "Theresa Lang",
    picture: theresa_lang,
    institute:
      "Institute for Technology & Innovation Management <br> RWTH Aachen University",
    url: "https://www.time.rwth-aachen.de/cms/TIME/Die-Research-Area/Technologie-und-Innovationsmanagement-/TIM-Team/Wissenschaftliche-Mitarbeiter/~bcycww/Lang-Theresa/",
    address: "Kackertstraße 7, 3. Etage, 52072 Aachen",
    email: "lang@time.rwth-aachen.de",
  },
]
