import Contacts from "../components/Contacts"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Hero from "../components/Hero"

export default function Contact() {
  

  return (
    <>
      <Header />
      <main className="min-h-screen">
        <Hero />
        <section className="w-full py-12 bg-white">
            <Contacts />
        </section>
      </main>
      <Footer />
    </>
  )
}