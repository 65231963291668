import About from "../components/About"
import Header from "../components/Header"
import Footer from "../components/Footer"

import OurGoals from "../components/OurGoals"
import TheApproach from "../components/TheApproach"
import { ScrollRestoration } from "react-router-dom"
import CallForAction from "../components/CallForAction"
import HeroSlider from "../components/HeroSlider"

export default function Home() {
  return (
    <>
      <ScrollRestoration
        getKey={(location, matches) => {
          // default behavior
          return location.key
        }}
      />
      <Header />
      <main className="min-h-screen">
        <HeroSlider />
        <About />
        <CallForAction />
        <OurGoals />
        <TheApproach />
      </main>
      <Footer />
    </>
  )
}
