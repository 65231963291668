import almut_balleer from "../images/team/almut_balleer.png"
import thomas_bergs from "../images/team/thomas_bergs.png"
import stefan_boeschen from "../images/team/stefan_boeschen.jpeg"
import elisabeth_clausen from "../images/team/elisabeth_clausen.jpeg"
import stefan_decker from "../images/team/stefan_decker.png"
import agnes_foerster from "../images/team/agnes_foerster.png"
import gabriele_gramelsberger from "../images/team/gabriele_gramelsberger.png"
import kathrin_greiff from "../images/team/kathrin_greiff.png"
import thomas_gries from "../images/team/thomas_gries.png"
import leif_kobbelt from "../images/team/leif_kobbelt.jpeg"
import peter_letmathe from "../images/team/peter_letmathe.png"
import michael_leuchner from "../images/team/michael_leuchner.png"
import matthias_mueller from "../images/team/matthias_mueller.jpeg"
import sonja_herrespawlis from "../images/team/sonja_herrespawlis.png"
import verena_nitsch from "../images/team/verena_nitsch.png"
import frank_piller from "../images/team/frank_piller.png"
import ferdinanda_ponci from "../images/team/ferdinanda_ponci.jpeg"
import aaron_praktiknjo from "../images/team/aaron_praktiknjo.png"
import bernhard_rumpe from "../images/team/bernhard_rumpe.png"
import marzia_traverso from "../images/team/marzia_traverso.png"
import sandra_venghaus from "../images/team/sandra_venghaus.jpeg"
import niklas_vonderassen from "../images/team/niklas_vonderassen.png"
import claudia_wagner from "../images/team/claudia_wagner.png"
import klaus_wehrle from "../images/team/klaus_wehrle.jpeg"
import florian_wellmann from "../images/team/florian_wellmann.png"

import SectionHeading from "./SectionHeading"
import Container from "./Container"
import TeamMember from "./TeamMember"
import CTAMember from "./CTAMember"

export default function TeamGrid() {
  return (
    <Container>
      <div className="my-2">
        <SectionHeading text="Team" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
        {teamMembers.map((member) => (
          <TeamMember key={member.name} member={member} />
        ))}
        <CTAMember />
      </div>
    </Container>
  )
}

const teamMembers = [
  {
    name: "Almut Balleer",
    picture: almut_balleer,
    institute: "Chair of Applied Economics <br> RWTH Aachen University",
    url: "https://www.ewifo.rwth-aachen.de/cms/~jqhs/ewifo/lidx/1/",
    address: "Templergraben 64, 52062 Aachen",
    email: "balleer@ewifo.rwth-aachen.de",
    expertise: ["business cycles", "labor and monetary economics"],
    publications: [
      "Balleer, B. Gherke, W. Lechthaler, C. Merkl (2016). Does Short-Time Work Save Jobs? A Business Cycle Analysis. European Economic Review, 84, 99-122. https://doi.org/10.1016/j.euroecorev.2015.05.007",
      "A. Balleer, M. Endrikat (2022). Air Quality in Welfare Accounting, 2022, Macroeconomic Dynamics, forthcoming, https://doi.org/10.1017/S1365100522000074",
    ],
  },
  {
    name: "Thomas Bergs",
    picture: thomas_bergs,
    institute:
      "Chair of Manufacturing Technology <br> RWTH Aachen University <br>Fraunhofer-Institute for Production Technology IPT",
    url: "https://www.wzl.rwth-aachen.de/cms/~sijq/wzl/?lidx=1",
    address: "Campus-Boulevard 30, 52074 Aachen",
    email: "t.bergs@wzl.rwth-aachen.de",
    expertise: [
      "Manufacturing technology",
      "Life cycle assessment in manufacturing",
      "Digitization of manufacturing technology",
    ],
    publications: [
      "T. Bergs, C. Brecher, R. Schmitt., G. Schuh (Hrsg.) (2021) Internet of Production - Turning Data into Sustainability, Apprimus, 978-3-86359-995-9. https://doi.org/10.24406/ipt-n-640533",
      "T. Helmig, B. Peng, C. Ehrenpreis, T. Augspurger, Y. Frekers, R. Kneer, T. Bergs (2019). A Coupling Approach Combining CFD and FEM Methods to Predict Cutting Fluid Effects on the Tool Temperature in Cutting Processes. Journal of Manufacturing Science and Engineering 141, 101003-1-6. https://doi.org/10.1115/1.4044102",
    ],
  },
  {
    name: "Stefan Böschen",
    picture: stefan_boeschen,
    institute:
      "Human Technology Center (HumTec)<br> RWTH Aachen University<br>Käte Hamburger International Center ‘Research Cultures’",
    url: "https://www.sotec.rwth-aachen.de/cms/~rkfy/SOTEC/?lidx=1",
    address: "Theaterplatz 14, 52062 Aachen",
    email: "stefan.boeschen@humtec.rwth-aachen.de",
    expertise: [
      "Science and Technology Studies",
      "Technology Assessment",
      "Collaborative Research and Innovation",
      "Knowledge Democracy",
    ],
    publications: [
      "S. Böschen, M. Legris, S. Pfersdorf, B.C. Stahl (2020). Identity Politics: Participatory Research and Its Challenges Related to Social and Epistemic Control. Social Epistemology 34/4, 382–394. https://doi.org/10.1080/02691728.2019.1706121",
      "Smolka, M., Böschen, S. (2023): Responsible innovation ecosystem governance: socio-technical integration research for systems-level capacity building. Journal of Responsible Innovation, https://doi.org/10.1080/23299460.2023.2207937",
    ],
  },
  {
    name: "Elisabeth Clausen",
    picture: elisabeth_clausen,
    institute:
      "Institute for Advanced Mining Technologies <br> RWTH Aachen University",
    url: "https://www.amt.rwth-aachen.de/en/home.html",
    address: "Wüllnerstr. 2, 52062 Aachen",
    email: "eclausen@amt.rwth-aachen.de",
    expertise: [
      "Mining 4.0",
      "Autonomous systems for modern high-performance mining",
    ],
    publications: [
      "E. Clausen, A. Sörensean (2022). Required and desired: breakthroughs for future-proofing mineral and metal extraction. Mineral Economics 35(3/4), 521-537. https://doi.org/10.18154/RWTH-2022-07050",
      "E. Clausen, A. Sörensen, F. D. Uth, R. Mitra, B. Schwarze, F. Lehnen (2020). Assessment of the Effects of Global Digitalization Trends on Sustainability in Mining Part I: Digitalization Processes in the Mining Industry in the Context of Sustainability. Aachen: Bundesanstalt für Geowissenschaften und Rohstoffe. https://www.bgr.bund.de/EN/Themen/Min_rohstoffe/Downloads/digitalization_mining_dustainability_part_I_en.pdf?__blob=publicationFile&v=4",
    ],
  },
  {
    name: "Stefan Decker",
    picture: stefan_decker,
    institute:
      "Chair of Computer Science 5 (Information Systems and Databases)<br> RWTH Aachen University<br>Director Fraunhofer FIT",
    url: "https://dbis.rwth-aachen.de/dbis/",
    address: "Ahornstraße 55, 52074 Aachen",
    email: "decker@dbis.rwth-aachen.de",
    expertise: ["Semantic Web"],
    publications: [
      "S. Decker, S. Melnik, F. Van Harmelen, D. Fensel, M. Klein, J. Broekstra, M. Erdmann, I. Horrocks (2000). The Semantic Web: the roles of XML and RDF. IEEE Internet Computing, 4(5):63-73.  https://doi.org/10.1109/4236.877487",
      "S. Decker, M. Erdmann, D. Fensel, R. Studer. Ontobroker (1999). ontology based access to distributed and semi-structured information. In Database Semantics - Semantic Issues In Multimedia Systems, IFIP Tc2/Wg2.6, Eighth Working Conference on Database Semantics (Ds-8), 351-369, Kluwer.",
    ],
  },
  {
    name: "Agnes Förster",
    picture: agnes_foerster,
    institute:
      "Chair of Planning Theory and Urban Development<br> RWTH Aachen University",
    url: "https://www.pt.rwth-aachen.de/cms/~shxt/PT/?lidx=1",
    address: "Wüllnerstraße 5b, 52062 Aachen",
    email: "foerster@pt.rwth-aachen.de",
    expertise: ["Urban transformation: spatial, economic, social issues"],
    publications: [
      "Förster, E. Strobel, S. Böschen, P. Letmathe, M. Paegert (2022). The Platform Approach. Stimulating Transformative Knowledge Creation for the Rhenish Lignite Mining Area. disP The Planning Review 58(3), 22-39.  https://doi.org/10.1080/02513625.2022.2158590",
      "A. Förster (2022). Alle wollen wirken: Transformative Forschung trifft Stadtentwicklung. In: pnd- rethinking planning, 2022 (1): 43-69.  https://doi.org/10.18154/RWTH-2022-05178",
    ],
  },
  {
    name: "Gabriele Gramelsberger",
    picture: gabriele_gramelsberger,
    institute:
      "Chair of the Philosophy of Science and Technology <br> RWTH Aachen University<br>Käte Hamburger International Center ‘Research Cultures’",
    url: "https://www.humtec.rwth-aachen.de/cms/~pttk/HUMTEC/?lidx=1",
    address: "Theaterplatz 14, 52062 Aachen",
    email: "gramelsberger@humtec.rwth-aachen.de",
    expertise: [
      "Digital transformation of science",
      "reflective modelling and simulation",
      "environmental humanities",
    ],
    publications: [
      "G. Gramelsberger (2018). Climate and Simulation. Oxford University Research Encyclopdia Climate Science.  https://doi.org/10.1093/acrefore/9780190228620.013.52",
      "G. Gramelsberger, J. Feichter (Eds., 2011). Climate Change and Policy. The Calculability of Climate Change and the Challenge of Uncertainty, Heidelberg/Berlin: Springer.",
    ],
  },
  {
    name: "Kathrin B. Greiff",
    picture: kathrin_greiff,
    institute:
      "Department of Anthropogenic Material Cycles <br> RWTH Aachen University",
    url: "https://www.ants.rwth-aachen.de/cms/~eeby/IAR/?lidx=1",
    address: "Wüllnerstraße 2, 52062 Aachen",
    email: "kathrin.greiff@ants.rwth-aachen.de",
    expertise: ["resource efficiency and circular economy"],
    publications: [
      "K. Greiff, M. Faulstich (2018). Resource Efficiency: Trends and the Potential of Circular Economy. CEC4Europe Factbook. URL: https://www.cec4europe.eu/wp-content/uploads/2018/11/Chapter-4.5_Ressource-Efficiency_Factbook-CEC4E_Faulstich_Greiff.pdf.",
      "K. Greiff, J. Teubler, C. Baedeker, C. Liedtke, H. Rohn, (2017). Material and Carbon Footprint of household activities. In D.V. Keyson, O. Guerra-Santin, D. Lockton, (Eds.): Living Labs. Design and Assessment of Sustainable Living. Springer, Heidelberg.  https://doi.org/10.1007/978-3-319-33527-8_20",
    ],
  },
  {
    name: "Thomas Gries",
    picture: thomas_gries,
    institute:
      "Chair of Textile Technology in Mechanical Engineering and Institute of Textile Technology <br> RWTH Aachen University",
    url: "https://www.ita.rwth-aachen.de/cms/~jezh/ITA/?lidx=1",
    address: "Otto-Blumenthal-Straße 1, 52074 Aachen",
    email: "thomas.gries@ita.rwth-aachen.de",
    expertise: ["mechanical engineering Technologies for Fibres & Textiles"],
    publications: [
      "T. Gries, D. Veit, B. Wulfhorst, (2015). Textile Technology: an Introduction 2nd Ed. - Munich [u.a.]: Hanser.",
      "T. Gries, M. Seidenberg, K. B. Müller (2022). Next generation textile industry. Proceedings of the ITMF Annual Conference 2022 : Climate Change and a Sustainable Global Textile Value Chain Davos.",
    ],
  },
  {
    name: "Sonja Herres-Pawlis",
    picture: sonja_herrespawlis,
    institute:
      "Chair of Bioinorganic Chemistry and Institute of Inorganic Chemistry <br> RWTH Aachen Univeristy",
    url: "https://www.bioac.ac.rwth-aachen.de/cms/~ictk/BIOAC/?lidx=1",
    address: "Landoltweg 1a, 52074 Aachen",
    email: "sonja.herres-pawlis@ac.rwth-aachen.de",
    expertise: ["(Bio-) Inorganic Chemistry", "Coordination Chemistry"],
    publications: [
      "S. Herres-Pawlis, F. Bach, I.J. Bruno, S.J. Chalk, N. Jung, J.C. Liermann, L.R. McEwen, S. Neumann, C. Steinbeck, M. Razum, O. Koepler (2022). Minimum Information Standards in Chemistry: A Call for Better Research Data Management Practices. Angewandte Chemie/ International edition, earlyview, e202203038.  https://doi.org/10.1002/anie.202203038",
      "S. Herres-Pawlis, O. Koepler, C. Steinbeck (2019). NFDI4Chem: Shaping a Digital and Cultural Change in Chemistry. Angewandte Chemie / International edition, 58 (32), 10766-10768.  https://doi.org/10.1002/anie.201907260. (Open Access)",
    ],
  },
  {
    name: "Leif Kobbelt",
    picture: leif_kobbelt,
    institute:
      "Chair for Computer Graphics & Multimedia<br>RWTH Aachen University",
    url: "http://www.graphics.rwth-aachen.de/",
    address: "Ahornstraße 55, 52074 Aachen",
    email: "sekretariati8@informatik.rwth-aachen.de",
    expertise: [
      "geometry acquisition and processing",
      "on interactive visualization",
    ],
    publications: [
      "M Pauly, M Gross, L. Kobbelt (2002).",
      "M. Lyon, M. Campen, L. Kobbelt (2021). Simpler Quad Layouts using Relaxed Singularities. Computer Graphics Forum, 40 (5), Symposium on Geometry Processing.  https://doi.org/10.1111/cgf.14365",
    ],
  },
  {
    name: "Peter Letmathe",
    picture: peter_letmathe,
    institute: "Chair of Controlling <br> RWTH Aachen University",
    url: "https://www.controlling.rwth-aachen.de/cms/~dlrg/Controlling/?lidx=1",
    address: "Templergraben 64, 52062 Aachen",
    email: "letmathe@controlling.rwth-aachen.de",
    expertise: [
      "Sustainability accounting: Environmental cost accounting",
      "climate change and emissions trading",
      "value chain management",
    ],
    publications: [
      "P. Letmathe, M. Rößler (2022). Should Firms use Digital Work Instructions? – Individual Learning in an Agile Manufacturing Setting. Journal of Operations Management. 68(19), 94-108.  https://doi.org/10.1002/joom.1159",
      "P. Letmathe., C. Roll, A. Balleer, S. Böschen, W. Breuer., A Förster, G. Gramelsberger, K. Greiff, R. Häußling, M. Lemme, M. Leuchner, M. Paegert, F. Piller, E. Seefried, T. Wahlbrink (eds.) (2023). Transformation towards Sustainability: A Novel Interdisciplinary Framework from RWTH Aachen University, SpringerNature, Cham/Switzerland.",
    ],
  },
  {
    name: "Michael Leuchner",
    picture: michael_leuchner,
    institute: "Institute of Geography <br> RWTH Aachen University",
    url: "https://www.klimageo.rwth-aachen.de/cms/~fesr/klimageo/?lidx=1",
    address: "Wüllnerstr. 5b, 52062 Aachen",
    email: "michael.leuchner@geo.rwth-aachen.de",
    expertise: [
      "Atmospheric environmental research",
      "Energy and matter fluxes between land surface and atmosphere",
      "Ecoclimatology",
      "Ecohydrology",
    ],
    publications: [
      "M. Leuchner (2022). Aktueller Klimazustand und zukünftige Klimaentwicklung – Einflussfaktoren, Folgen und Herausforderungen. In: W. Frenz (Hrsg.): Klimaschutzrecht – Gesamtkommentar. 2. Aufl. Erich Schmidt Verlag, Berlin. ISBN 978-3-503-20686-5.",
      "O. Reitz, H. Bogena, B. Neuwirth, A. Graf, A. Sanchez-Azofeifa, C. Montzka, M. Leuchner (2023): Environmental Drivers of Gross Primary Productivity and Light Use Efficiency of a Temperate Spruce Forest. Journal of Geophysical Research – Biogeosciences.  https://doi.org/10.1029/2022JG007197 (Open Access)",
    ],
  },
  {
    name: "Matthias S. Müller",
    picture: matthias_mueller,
    institute:
      "Chair of Computer Science 12 (High Performance Computing)<br>Director IT Center<br>RWTH Aachen University",
    url: "https://www.itc.rwth-aachen.de/cms/~rbnjb/it-center/?lidx=1",
    address: "Seffenter Weg 23, 52074 Aachen",
    email: "mueller@itc.rwth-aachen.de",
    expertise: [
      "Programming models and tools for parallel and multicore computers and the performance and correctness analysis of parallel programs.",
    ],
    publications: [
      "J. Dongarra, P. Beckman, M. S. Müller, et al. (2011). The international exascale software project roadmap. International Journal of High Performance Computing Applications, 25 (1), 3-60.  https://doi.org/10.1177/1094342010391989",
      "M. S. Müller, M. van Waveren, R Lieberman, B Whitney, H Saito, K Kumaran, et. al. (2007). SPEC MPI2007—an application benchmark suite for parallel systems using mpi. Concurrency and Computation: Practice and Experience 22 (2), 191-205.  https://doi.org/10.1002/cpe.1535",
    ],
  },
  {
    name: "Verena Nitsch",
    picture: verena_nitsch,
    institute:
      "Institute of Industrial Engineering and Ergonomics <br> RWTH Aachen University <br>Fraunhofer Institute of Communication, Information Processing and Ergonomics",
    url: "https://www.iaw.rwth-aachen.de/cms/~ieplw/IAW/?lidx=1",
    address: "Eilfschornsteinstr. 18, 52062 Aachen",
    email: "v.nitsch@iaw.rwth-aachen.de",
    expertise: [
      "Work organization",
      "Education for technical professions Ergonomics and human-machine systems",
    ],
    publications: [
      "V. Nitsch, M. Popp (2014). Emotions in robot psychology. Biological cybernetics, 621-629.  https://doi.org/10.1007/s00422-014-0594-6",
      "C. Brandl, V. Nitsch (2022). Future of Work in Germany: Socially sustainable value creation in digitalized and AI-supported work systems. Work: A Journal of Prevention, Assessment & Rehabilitation, 1493-1496.  https://doi.org/10.3233/WOR-223639",
    ],
  },
  {
    name: "Frank Piller",
    picture: frank_piller,
    institute:
      "Institute for Technology & Innovation Management <br> RWTH Aachen University",
    url: "https://www.time.rwth-aachen.de/cms/TIME/Die-Research-Area/~ehfb/Technologie-und-Innovationsmanagement-/?lidx=1",
    address: "Kackertstraße 7, 3. Etage, 52072 Aachen",
    email: "piller@time.rwth-aachen.de",
    expertise: [
      "customer-centric value creation: mass customization",
      "personalization and open innovation",
    ],
    publications: [
      "F. Piller, V. Nitsch, D. Lüttgens, A. Mertens, S. Pütz, M. Van Dyck (Editors). Forecasting Next Generation Manufacturing: How Digital Shadows, Human-Machine Collaboration, and Data-driven Business Models Impact the Future Production Ecosystem. Cham: SpringerNature 2022.  https://doi.org/10.1007/978-3-031-07734-0",
      "S. Hankammer, R. Kleer, F.T. Piller (2021). Sustainability nudges in the context of customer co-design for consumer electronics. Journal of Business Economics 91 (6), 897-933.  https://doi.org/10.1007/s11573-020-01020-x",
    ],
  },
  {
    name: "Ferdinanda Ponci",
    picture: ferdinanda_ponci,
    institute: "Institute for Automation of Complex Power Systems",
    url: "https://www.acs.eonerc.rwth-aachen.de/cms/~dlkd/E-ON-ERC-ACS/lidx/1/",
    address: "Mathieustrasse 10, 52074 Aachen",
    email: "post_mod@eonerc.rwth-aachen.de",
    expertise: [
      "Monitoring and control of energy networks",
      "ICT solutions for advanced energy services",
    ],
    publications: [
      "M. Pau, E. Patti, L. Barbierato, A. Estebsari, E. Pons, F. Ponci, A. Monti (2018). A cloud-based smart metering infrastructure for distribution grid services and automation. Sustainable Energy, Grids and Networks, 15, 14-25.  https://doi.org/10.1016/j.segan.2017.08.001",
      "S.D.J. McArthur, E.M. Davidson , V.M. Catterson, A. Dimeas, N. Hatziargyriou, F. Ponci, T. Funabashi (2010) Multi-Agent Systems for Power Engineering Applications. IEEE Transactions on Power Systems, 22(4), 1743 - 1752.",
    ],
  },
  {
    name: "Aaron J. Praktiknjo",
    picture: aaron_praktiknjo,
    institute: "Chair for Energy System Economics <br>RWTH Aachen University",
    url: "https://www.fcn.eonerc.rwth-aachen.de/cms/~dndh/E-ON-ERC-FCN/?lidx=1",
    address: "Mathieustr. 10, 52074 Aachen",
    email: "apraktiknjo@eonerc.rwth-aachen.de",
    expertise: [
      "Energy System Analysis",
      "Energy markets and regulation",
      "Sustainability assessment of energy technologies",
    ],
    publications: [
      "J. Priesmann, S. Spiegelburg, R. Madlener, A. Praktiknjo (2022). Does renewable electricity hurt the poor? Exploring levy programs to reduce income inequality and energy poverty across German households. Energy Research & Social Science, 93, 102812.  https://doi.org/10.1016/j.erss.2022.102812",
      "C. Kockel, L. Nolting, J. Priesmann, A. Praktiknjo (2022). Does renewable electricity supply match with energy demand? – A spatio-temporal analysis for the German case. Applied Energy, 308, 118226.  https://doi.org/10.1016/j.apenergy.2021.118226",
    ],
  },
  {
    name: "Bernhard Rumpe",
    picture: bernhard_rumpe,
    institute:
      "Chair of Computer Science 3 (Software Engineering) <br> RWTH Aachen University",
    url: "https://se-rwth.github.io/",
    address: "Ahornstraße 55, 52074 Aachen",
    email: "rumpe@se.rwth-aachen.de",
    expertise: ["Generative Programming", "Software Language Engineering"],
    publications: [
      "B. Rumpe. Agile Modeling with UML (2017). Code Generation, Testing, Refactoring. Springer International.  https://doi.org/10.1007/978-3-319-58862-9",
      "I. Drave, O. Kautz, J. Michael, B. Rumpe (2019). Semantic Evolution Analysis of Feature Models. In: International Systems and Software Product Line Conference (SPLC'19), pp. 245-255, ACM, Paris.  https://doi.org/10.1145/3336294.3336300",
    ],
  },
  {
    name: "Marzia Traverso",
    picture: marzia_traverso,
    institute:
      "Institute of Sustainability in Civil Engineering <br> RWTH Aachen University",
    url: "https://www.inab.rwth-aachen.de/en/welcome-to-inab/",
    address: "Mies-van-der-Rohe-Str. 1, 52074 Aachen",
    email: "marzia.traverso@inab.rwth-aachen.de",
    expertise: [
      "Toolbox Sustainable Engineering",
      "Life Cycle Sustainability Assessment",
    ],
    publications: [
      "M. Traverso, R. Nangah Mankaa (2022). The Sustainable Development Goals. In: The Palgrave Handbook of Global Sustainability. Palgrave Macmillan, Cham. https://doi.org/10.1007/978-3-030-38948-2_83-1",
      "J.G. Backes, M. Traverso (2022). Social Life Cycle Assessment in the construction industry – systematic literature review and identification of relevant social indicators for carbon reinforced concrete. Environment, Development and Sustainability. DOI: https://doi.org/10.1007/s10668-023-03005-6",
    ],
  },
  {
    name: "Sandra Venghaus",
    picture: sandra_venghaus,
    institute:
      "Junior Professorship of Decision Analysis and Socio-economic Assessment <br> RWTH Aachen University",
    url: "https://www.socecon.rwth-aachen.de/",
    address: "Kackertstr. 7, 52072 Aachen",
    email: "venghaus@socecon.rwth-aachen.de",
    expertise: [
      "Governance of the Sustainability Transformation",
      "Innovation in Socio-Ecological Systems",
      "Sustainability Assessment",
    ],
    publications: [
      "S. Venghaus, M. Henseleit, M. Belka (2022). The impact of climate change awareness on behavioral changes in Germany: changing minds or changing behavior?. Energy, Sustainability and Society, 12 (8).  https://doi.org/10.1186/s13705-022-00334-8",
      "S. Venghaus, J.-Fr. Hake (2018). Nexus thinking in current EU policies – The interdependencies among food, energy and water resources. Environmental Science and Policy, 90, 183-192.  https://doi.org/10.1016/j.envsci.2017.12.014",
    ],
  },
  {
    name: "Niklas von der Aßen",
    picture: niklas_vonderassen,
    institute:
      "Chair and Institute of Technical Thermodynamics <br> RWTH Aachen University",
    url: "https://www.ltt.rwth-aachen.de/cms/~ivms/LTT/?lidx=1",
    address: "Schinkelstr. 8, 52062 Aachen",
    email: "niklas.vonderassen@ltt.rwth-aachen.de",
    expertise: [
      "Energy Systems Engineering",
      "Modeling and design of molecular systems",
      "Measurement systems engineering",
    ],
    publications: [
      "J. Hense, M. Bachmann, L. Polte, N. von der Assen, A. Jupke (2022). Integrated Process Design and Life Cycle Assessment of Carbon Monoxide Provision from Basic Oxygen Furnace Gas. Chemie Ingenieur Technik, 94(10), 1524-1535.  https://doi.org/10.1016/j.est.2022.105964",
      "N. von der Assen, L.J. Müller, A. Steingrube, P. Voll, A. Bardow (2016). Selecting CO2 sources for CO2 utilization by environmental-merit-order curves, Environ. Sci. Technol, 50(3), 1093-1101.  https://doi.org/10.1021/acs.est.5b03474",
    ],
  },
  {
    name: "Claudia Wagner",
    picture: claudia_wagner,
    institute:
      "Chair for Applied Computational Social Sciences <br> RWTH Aachen University",
    url: "https://www.sth.rwth-aachen.de/cms/fachgruppe/die-fachgruppe/~jwcez/Lehrstuhl-fuer-Angewandte-computerbasier/?lidx=1",
    address: "",
    email: "claudia.wagner@humtec.rwth-aachen.de",
    expertise: [
      "Computational Social Science",
      "Text and Data Mining",
      "Algorithmic Bias and Machine Learning",
    ],
    publications: [
      "C. Wagner, M. Strohmaier, A. Olteanu, A. et al. (2021). Measuring algorithmically infused societies. Nature 595, 197–204.  https://doi.org/10.1038/s41586-021-03666-1",
      "J. Kulshrestha, O. Karcalik, M. Oliveira, and C. Wagner (2021). Limits of Predictability: Investigating Demographic and Behavioral Differences Using Web Tracking Data. In: Proceedings of International Conference on Weblogs and Social Media (ICWSM), 06 2021.",
    ],
  },
  {
    name: "Klaus Wehrle",
    picture: klaus_wehrle,
    institute:
      "Chair of Communication and Distributed Systems (COMSYS, Informatik 4) <br> RWTH Aachen University",
    url: "https://www.comsys.rwth-aachen.de/home",
    address: "Ahornstr. 55, 52074 Aachen",
    email: "klaus@comsys.rwth-aachen.de",
    expertise: [
      "Network and System Reliability and Security",
      "Massively Distributed Systems",
      "Performance Evaluation and Hybrid Evaluation Platforms",
      "Cross-Layer Techniques & Platforms",
    ],
    publications: [
      "K. Wehrle, M. Günes, J. Gross (Eds.) (2010). Modeling and Tools for Network Simulation. Springer: Cham (textbook and methodological compendium on simulation-based systems and network analysis)  https://doi.org/10.1007/978-3-642-12331-3",
      "R. Glebke, J. Scheiper, S. Lenz, M. Stoffers, K. Wehrle (2022). Harnessing Cooperative Anycast Communication for Increased Resilience in Wireless Control. Proc. IEEE Conf. Decision & Control (CDC).  https://doi.org/10.1109/CDC51059.2022.9992864",
    ],
  },
  {
    name: "Florian Wellmann",
    picture: florian_wellmann,
    institute:
      "Chair of Computational Geoscience, Geothermics and Reservoir Geophysics <br> RWTH Aachen University",
    url: "https://www.cg3.rwth-aachen.de/cms/~qoyf/cg3/?lidx=1",
    address: "Mathieustr. 30,52074 Aachen",
    email: "florian.wellmann@cgre.rwth-aachen.de",
    expertise: [
      "Probabilistic geological 3D modeling",
      "Geophysical process simulations",
      "Machine learning in the geosciences",
    ],
    publications: [
      "J. F. Wellmann, S. Finsterle, and A. Croucher. Integrating Structural Geological Data into the Inverse Modelling Framework of iTOUGH2. Computers and Geosciences 65 (2014): 95-109.,",
      "J. F. Wellmann. Information Theory for Correlation Analysis and Estimation of Uncertainty Reduction in Maps and Models. Entropy 15.4 (2013): 1464-1485.",
    ],
  },
]
