import { Link } from "react-router-dom"
import SectionHeading from "./SectionHeading"
export default function CTAMember() {
  return (
    <div className="bg-gradient-to-tr from-ng-lightblue to-ng-green rounded-lg shadow-lg overflow-hidden h-full flex items-center">
      <div className=" p-4 rounded-md text-white text-center relative">
        <div className="my-2 ">
          <SectionHeading text="Call for Action" white={true} />
        </div>
        <div className="my-4">
          <p className=" md:text-xl text-lg">
            We seek committed researchers like you to join our open science
            research group! <br />
            <br />
            If you are interested, please:
          </p>
          <Link to="/contact">
            <button className="rounded-md bg-white text-ng-lightblue font-bold px-4 py-2 text-lg md:text-xl mt-6">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
