export default function TeamMember({ member }) {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <img
              className="object-cover h-32 w-32 rounded-full"
              src={member.picture}
              alt={member.name}
            />
          </div>
          <div className="ml-4">
            <h3 className="text-xl leading-6 font-medium text-gray-800">
              {member.name}
            </h3>
            <p
              target="_blank"
              className="mt-1 max-w-2xl sm:text-sm text-xs text-gray-500 font-bold "
              dangerouslySetInnerHTML={{ __html: member.institute }}
            ></p>
            <p
              className="mt-1 max-w-2xl sm:text-sm text-xs text-gray-500"
              dangerouslySetInnerHTML={{ __html: member.address }}
            ></p>
            <a href={`mailto:${member.email}`} className="underline">
              <p className="mt-1 max-w-2xl sm:text-sm text-xs text-gray-500 hover:text-ng-lightblue">
                {member.email}
              </p>
            </a>
            {member.url && (
              <a
                href={member.url}
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="mt-1 max-w-2xl sm:text-sm text-xs text-gray-500 hover:text-ng-lightblue">
                  Website ↗
                </p>
              </a>
            )}
          </div>
        </div>
        <div className="mt-4">
          <h4 className="sm:text-sm text-xs font-medium text-gray-500">
            Areas of Expertise
          </h4>
          <div className="mt-2 flex flex-wrap">
            {member.expertise.map((expertise) => (
              <span
                key={expertise}
                className="uppercase inline-block bg-ng-lightblue rounded-full px-3 py-1 sm:text-sm text-xs font-semibold text-white mr-2 mb-2"
              >
                {expertise}
              </span>
            ))}
          </div>
          <h4 className="sm:text-sm text-xs font-medium text-gray-500 mt-4">
            Publications
          </h4>
          <ul className="mt-2 sm:text-sm text-xs text-gray-500">
            {member.publications.map((publication) => (
              <li key={publication} className="ml-4">
                - {publication}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
