export default function ContactMember({ member }) {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <img
              className="object-cover h-32 w-32 rounded-full"
              src={member.picture}
              alt={member.name}
            />
          </div>
          <div className="ml-4">
            <h3 className="text-xl leading-6 font-medium text-gray-800">
              {member.name}
            </h3>
            <p
              className="mt-1 max-w-2xl text-sm text-gray-500 font-bold"
              dangerouslySetInnerHTML={{ __html: member.institute }}
            ></p>
            <p
              className="mt-1 max-w-2xl text-sm text-gray-500"
              dangerouslySetInnerHTML={{ __html: member.address }}
            ></p>
            <a href={`mailto:${member.email}`} className="underline">
              <p className="mt-1 max-w-2xl text-sm text-gray-500 hover:text-ng-lightblue">
                {member.email}
              </p>
            </a>
            {member.url && (
              <a
                href={member.url}
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="mt-1 max-w-2xl sm:text-sm text-xs text-gray-500 hover:text-ng-lightblue">
                  Website ↗
                </p>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
