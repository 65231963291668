import logo from "../images/logo_wide_with_rwth.png"
import { Link, NavLink } from "react-router-dom"

export default function Footer() {
  return (
    <footer className="bg-white w-full min-h-[200px] py-12">
      <div
        className="flex justify-between sm:max-w-6xl mx-auto w-full py-4 px-4 lg:px-0 "
        id="header-inner"
      >
        <div id="logo">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="max-w-[200px] md:max-w-[400px]"
            />
          </Link>
        </div>
        <div id="nav" className="flex items-center">
          <ul className="flex space-x-4">
            <li>
              <NavLink
                className={({ isActive, isPending }) =>
                  isActive ? "font-bold" : ""
                }
                to="/imprint"
              >
                Imprint
              </NavLink>
            </li>

            <li>
              <NavLink
                className={({ isActive, isPending }) =>
                  isActive ? "font-bold" : ""
                }
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="sm:max-w-6xl mx-auto px-4">
        <hr className="w-full border-gray-200 my-4" />
        <p className="text-sm text-gray-400">
          © 2023 The Institute for Technology and Innovation Management
          | RWTH Aachen
        </p>
      </div>
    </footer>
  )
}
