import heroLogo from "../images/logo_n_lightblue_square.png"
import Container from "./Container"

export default function Hero() {
  return (
    <section className="w-full py-12 bg-ng-green">
      <Container>
        <div className="grid grid-cols-3">
          <div className="col-span-2">
            <h1 className="md:text-6xl text-2xl text-white font-bold">
              Next Generation Sustainability
            </h1>
            <h4 className="text-white md:text-2xl text-xl mt-4">
              A RWTH Research Group aiming at integrating societal, ecological,
              and economic sustainability.
            </h4>
          </div>
          <div className="col-span-1">
            <img
              src={heroLogo}
              alt="heroLogo"
              className="max-w-[80%] ml-auto"
            />
          </div>
        </div>
      </Container>
    </section>
  )
}
