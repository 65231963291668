import Container from "./Container"
import SectionHeading from "./SectionHeading"

export default function About() {
  return (
    <section className="w-full py-12 bg-white">
      <Container>
        <div className="my-2">
          <SectionHeading text="About" />
        </div>
        <div className="my-4">
          <p className=" md:text-lg">
            We are an interdisciplinary research group from RWTH Aachen focusing
            on developing a holistic approach for sustainability assessment,
            aiming to cover all all 17 Sustainable Development Goals (SDGs)
            adopted by the United Nations in 2015.
          </p>
        </div>
      </Container>
    </section>
  )
}
