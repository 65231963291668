import Container from "./Container"
import SectionHeading from "./SectionHeading"

import icon_three_chips from "../images/icon_three_chips.svg"
import icon_leaf_chip from "../images/icon_leaf_chip.svg"
import icon_tags from "../images/icon_tags.svg"
import icon_backarrow from "../images/icon_backarrow.svg"
import icon_circles from "../images/icon_circles.svg"
import icon_sign from "../images/icon_sign.svg"

export default function OurGoals() {
  return (
    <section className="w-full bg-white">
      <Container>
        <div className="my-2">
          <SectionHeading text="Our Goals" />
        </div>
        <div className="my-6">
          <div className="grid md:grid-cols-3 grid-cols-2 gap-8">
            <GridItem>
              <img
                src={icon_three_chips}
                alt="icon 3 computer chips"
                className="max-w-[80%] w-[100px]"
              />
              <p className="sm:text-base text-xs">
                We create a <strong>holistic approach</strong> for
                sustainability assessment.
              </p>
            </GridItem>

            <GridItem>
              <img
                src={icon_leaf_chip}
                alt="icon leaf and chip"
                className="max-w-[80%] w-[100px]"
              />
              <p className="sm:text-base text-xs">
                We bridge the gap between top-down and bottom up{" "}
                <strong>sustainability analysis</strong>.
              </p>
            </GridItem>
            <GridItem>
              <img
                src={icon_tags}
                alt="icon tags"
                className="max-w-[80%] w-[100px]"
              />
              <p className="sm:text-base text-xs">
                We focus on the sustainability assessment{" "}
                <strong>of technologies, products, and policies.</strong>
              </p>
            </GridItem>
            <GridItem>
              <img
                src={icon_backarrow}
                alt="icon back arrow"
                className="max-w-[80%] w-[100px]"
              />
              <p className="sm:text-base text-xs">
                We use{" "}
                <strong>novel strategies for knowledge production</strong> on
                sustainability assessment.
              </p>
            </GridItem>
            <GridItem>
              <img
                src={icon_circles}
                alt="icon circles"
                className="max-w-[80%] w-[100px]"
              />
              <p className="sm:text-base text-xs">
                We take a new perspective to{" "}
                <strong>
                  explore interlinkages, trade-offs and synergies.
                </strong>
              </p>
            </GridItem>
            <GridItem>
              <img
                src={icon_sign}
                alt="icon open sign"
                className="max-w-[80%] w-[100px]"
              />
              <p className="sm:text-base text-xs">
                We use a{" "}
                <strong>
                  participatory open science approach to fully open-up the
                  development
                </strong>{" "}
                for stakeholders worldwide.
              </p>
            </GridItem>
          </div>
        </div>
      </Container>
    </section>
  )
}

const GridItem = ({ children }) => {
  return (
    <div className="aspect-square mx-auto rounded-xl bg-white shadow-xl border-solid border-2 border-gray-100 flex flex-wrap px-2 py-2 justify-center text-center">
      {children}
    </div>
  )
}
