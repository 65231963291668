import heroLogo from "../images/logo_n_lightblue_square.png"
import Container from "./Container"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

export default function HeroSlider() {
  return (
    <section className="w-full py-12 bg-ng-green">
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={10000}
        infiniteLoop={true}
        transitionTime={2000}
        stopOnHover={false}
      >
        <Container>
          <div className="grid grid-cols-3 text-left">
            <div className="col-span-2">
              <h1 className="md:text-6xl text-3xl text-white font-bold">
                Next Generation Sustainability
              </h1>
              <h4 className="text-white md:text-3xl text-2xl mt-4">
                A RWTH Research Group aiming at integrating societal,
                ecological, and economic sustainability.
              </h4>
            </div>
            <div className="col-span-1 text-right">
              <img
                src={heroLogo}
                alt="heroLogo"
                className="max-w-[80%] ml-auto"
              />
            </div>
          </div>
        </Container>
        <Container>
          <div className="grid grid-cols-3 text-left">
            <div className="col-span-2">
              <h1 className="md:text-6xl text-3xl text-white font-bold">
                Can we achieve more than the 2 degree target of climate change?
              </h1>
              <h4 className="text-white md:text-3xl text-2xl mt-4">
                Yes, if we develop and operationalize a holistic sustainability
                understanding.
              </h4>
            </div>
            <div className="col-span-1 text-right">
              <img
                src={heroLogo}
                alt="heroLogo"
                className="max-w-[80%] ml-auto"
              />
            </div>
          </div>
        </Container>
        <Container>
          <div className="grid grid-cols-3 text-left">
            <div className="col-span-2">
              <h1 className="md:text-6xl text-3xl text-white font-bold">
                Can new technologies promise ambitious solutions?
              </h1>
              <h4 className="text-white md:text-3xl text-2xl mt-4">
                Yes, if we ensure that they are complementing each other and
                develop synergies.
              </h4>
            </div>
            <div className="col-span-1 text-right">
              <img
                src={heroLogo}
                alt="heroLogo"
                className="max-w-[80%] ml-auto"
              />
            </div>
          </div>
        </Container>
      </Carousel>
    </section>
  )
}
