import logo from "../images/logo_wide.png"
import { Link, NavLink } from "react-router-dom"

export default function Header() {
  return (
    <header className="bg-white w-full min-h-[40px] sticky">
      <div
        className="flex justify-between sm:max-w-6xl mx-auto w-full py-4 px-4 lg:px-0 "
        id="header-inner"
      >
        <div id="logo">
          <Link to="/">
            <img src={logo} alt="logo" className="sm:max-w-[200px] max-w-[150px]" />
          </Link>
        </div>
        <div id="nav" className="flex items-center">
          <ul className="flex space-x-4">
            <li>
              <NavLink
                className={({ isActive, isPending }) =>
                  isActive ? "font-bold" : ""
                }
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive, isPending }) =>
                  isActive ? "font-bold" : ""
                }
                to="/team"
              >
                Team
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive, isPending }) =>
                  isActive ? "font-bold" : ""
                }
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}
