import Container from "./Container"
import SectionHeading from "./SectionHeading"

import icon_lightbulb_plus from "../images/icon_lightbulb_plus.svg"

export default function TheApproach() {
  return (
    <section className="w-full bg-ng-blue py-12">
      <Container>
        <div className="grid grid-cols-2 gap-8">
          <div className="grid grid-flow-row text-white text-center gap-12">
            <div className="mx-auto">
              <div className="flex justify-center">
                <img
                  src={icon_lightbulb_plus}
                  alt="lightbulb icon"
                  className="w-[50px]"
                />
              </div>
              <h4 className="text-xl">
                Considering all 17 SDGs, 169 targets and 231 indicators.
              </h4>
              {/* <p className="text-md">
                ‍based on 17 SDGs, 169 targets and 231 indicators{" "}
              </p> */}
            </div>
            <div className="mx-auto">
              <div className="flex justify-center">
                <img
                  src={icon_lightbulb_plus}
                  alt="lightbulb icon"
                  className="w-[50px]"
                />
              </div>
              <h4 className=" text-xl">
                Create an integrated assessment of sustainability of an emerging
                technology, policy, or business model.
              </h4>
              {/* <p className="text-md">
                e.g. technology, policy or business model.
              </p> */}
            </div>
            <div className="mx-auto">
              <div className="flex justify-center">
                <div className="flex justify-center">
                  <img
                    src={icon_lightbulb_plus}
                    alt="lightbulb icon"
                    className="w-[50px]"
                  />
                </div>
              </div>
              <h4 className=" text-xl">
                Recognize interlinkages between SDGs and indicators.
              </h4>
              {/* <p className="text-md">
                e.g. through aggregation of different indicators
              </p> */}
            </div>
            <div className="mx-auto">
              <div className="flex justify-center">
                <img
                  src={icon_lightbulb_plus}
                  alt="lightbulb icon"
                  className="w-[50px]"
                />
              </div>
              <h4 className=" text-xl">
                Add different levels of analysis to increase the impact of
                assessments, like global, national, regional, industry,
                product-level.
              </h4>
              {/* <p className="text-md">
                e.g. global, national, regional, industry, product-level
              </p> */}
            </div>
            <div className="mx-auto">
              <div className="flex justify-center">
                <img
                  src={icon_lightbulb_plus}
                  alt="lightbulb icon"
                  className="w-[50px]"
                />
              </div>
              <h4 className=" text-xl">
                Make assessment agile and allow for adaptation options to
                account for potential changes.
              </h4>
              {/* <p className="text-md">e.g. change in policies </p> */}
            </div>
          </div>
          <div className="">
            <SectionHeading text="The Approach" white={true} />
            <p className="text-white mt-4">
              Developing technologies for sustainable development is one side of
              the medal; implementing these technologies in an environmentally
              and socioeconomically sustainable way the other.
              <br />
              <br />
              We are working on a new approach to assess sustainability of
              emerging technologies, business models or policies. In our
              approach, we strive to integrate all SDG targets and aggregate
              different indicators. We aim to develop a metric not only for the
              global and national level, but also the regional, industry and
              even product level. <br />
              <br />
              Our key questions are: How can we assess sustainability, to
              determine the best future advances? How can we realize this vision
              of ours?
            </p>
          </div>
        </div>
      </Container>
    </section>
  )
}
