
import Container from "../components/Container"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Hero from "../components/Hero"

import SectionHeading from "../components/SectionHeading"

export default function Imprint() {
    return (
        <>
          <Header />
          <main className="min-h-screen">
            <Hero />
            <section className="w-full py-12 bg-white">
            <Container>
                <div className="my-2">
                    <SectionHeading text="Imprint" />
                </div>
                <div>
                    <p className="font-bold">Information in accordance with section 5 TMG</p>
<p>The Institute for Technology and Innovation Management
  
Kackertstraße 7
52072 Aachen
</p>

<br />
<p className="font-bold">Contact:</p>

<p>E-Mail: piller@time.rwth-aachen.de</p>
<br />
<p className="font-bold">Person responsible for content in accordance with 55 Abs. 2 RStV</p>


<p>Prof. Dr. Frank Piller, Institute for Technology and Innovation Management (TIM), RWTH Aachen University, Kackertstr. 7, 52072 Aachen, Germany</p>
</div>
            </Container>
            </section>
          </main>
          <Footer />
        </>
      )
}